import * as React from "react"
import {graphql} from "gatsby";

import Seo from "../layout/seo";
import Layout from "../layout/layout";
import HeaderImage from "../components/headerImage";
import ResourceDetails from "../components/resources/resourceDetails";
import GetResourceType from "../components/resources/resourceType";

const LayoutResource = ({ data, location }) => {
  const resourceType = GetResourceType(data.nodeResource.id)
  const className = resourceType === 'patients'?'blue':(resourceType === 'caregivers'?'green':'')

  return <Layout additionalClass={className} resourceID={data.nodeResource.id}>
    {data.nodeResource.field_resource_type !== 'Video' && <HeaderImage node={data.nodeResource}/>}
    <ResourceDetails node={data.nodeResource} />
  </Layout>
}

export const Head = ({ data }) => <Seo
  title={data.nodeResource.title}
  metaTags={data.nodeResource.field_meta_tags}
  ldjson={data.nodeResource.relationships.field_ld_json}
/>

export default LayoutResource

export const query = graphql`
  query ($id: String) {
    nodeResource(id: {eq: $id}) {
      field_use_alternative_layout
      field_resource_type
      field_attached_page_link_text
      field_meta_tags {
        title
        description
      }
      id
      path {
        alias
      }
      title
      field_thumbnail {
        alt
      }
      field_header_image {
        alt
      }
      relationships {
        field_attached_page {
          ... on node__page {
            id
            path {
              alias
            }
          }
          ... on node__resource {
            id
            path {
              alias
            }
          }
        }
        field_ld_json: field_ld_json2 {
          field__type
          field_pair_1_name
          field_pair_1_value_text_
          field_pair_2_name
          field_pair_2_value_text_
          relationships {
            field_pair_1_ {
              localFile {
                publicURL
              }
            }
            field_pair_2_image {
              localFile {
                publicURL
              }
            }
          }
        }
        field_attached_file {
          localFile {
            publicURL
          }
        }
        field_attached_video {
          field_video_id
          title
          relationships {
            field_thumbnail {
              localFile {
                publicURL
              }
            }
          }
        }
        field_mobile_header_image {
            localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        field_header_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        
        field_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        field_content {
            type: __typename
          ... ParagraphBoxedText
          ... ParagraphTextBlock
          ... ParagraphSubheader
          ... ParagraphHeader
          ... ParagraphBulletedList
          ... ParagraphArticleBlock
          ... ParagraphArticleMoreInfoBlock
        }
        field_related_items {
          id
          title
          field_resource_type
          path {
            alias
          }
          field_thumbnail {
            alt
          }
          
          field_header_image {
            alt
          }
          
          relationships {
            field_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            field_header_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        
      }
    }
  }
`