import * as React from "react"

const EducationPurposeNote = () => {
  return <div className="text-bot container">
    <p>This information is for educational purposes only and should not take the place of talking to your doctor or a
      healthcare professional. The content included on this page does not constitute medical advice and should not be
      used for diagnosing or treating a health problem or disease. Please be sure to always consult with a physician or
      medical professional for questions about your medical condition.</p>
  </div>
}

export default EducationPurposeNote