import * as React from "react"
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";



const ResourceArticleSidebar = ({nodes}) => {

  let aRelated = nodes


  const html =  <div className="article__related right-column">
    <h2>Related Items</h2>
    {
      aRelated.map((item, index) => {
        let image = item.relationships.field_header_image?item.relationships.field_header_image.localFile.childImageSharp.gatsbyImageData:null
        let alt = item.field_header_image?item.field_header_image.alt:''

        return <div className="right-column__item" key={'related_items' + index}>
          {image && <Link to={item.path.alias} className={'right-column__media'}>
            <GatsbyImage alt={alt} image={image} className={'d-block'}/>
            {item.field_resource_type === 'Video' && <div className="video__play"></div>}
          </Link>}
          <Link to={item.path.alias} className={'link'}>{item.title}</Link>
        </div>
      })
    }
  </div>

  return aRelated.length > 0?html:<></>
}

export default ResourceArticleSidebar