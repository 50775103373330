import * as React from "react"
import {getParagraph} from "../../paragraps/paragraph_Handler";
import ResourceArticleSidebar from "./resourceArticleSidebar";
import EducationPurposeNote from "../educationPurposeNote";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import GetResourceType from "./resourceType";

const ResourceDetails = ({node}) => {
  switch (node.field_resource_type) {
    case 'Video': return __ShowVideoContent(node);
    case 'Article': return __ShowArticleContent(node);
    case 'Infographic': return __ShowInfographicsContent(node);
    case 'Page': return __ShowPageContent(node);
    default: return <React.Fragment></React.Fragment>;
  }

  return <React.Fragment>Unknown resource</React.Fragment>
}

export default ResourceDetails

const __ShowArticleContent = (node) => {
  return node.field_use_alternative_layout?__ShowArticleContentWithFile(node):__ShowArticleContentWithoutFile(node);
}

const __ShowArticleContentWithFile = (node) => {
  const image = node.relationships.field_thumbnail != null?getImage(node.relationships.field_thumbnail.localFile.childImageSharp.gatsbyImageData):null
  const paragraphs = node.relationships.field_content.map(getParagraph);
  const fileLink = node.relationships.field_attached_file?node.relationships.field_attached_file.localFile.publicURL:false

  const taxType = GetResourceType(node.id);
  const buttonClass = taxType === 'caregivers'?'button-green':'button-blue';

  return <React.Fragment>
    <section className="article container">
      <div className="article__text article-text">
        {paragraphs}
      </div>

      <div className="article__download">
        {image && <Link to={fileLink} className={'download__img'} target={'_blank'}><GatsbyImage alt={node.field_thumbnail?node.field_thumbnail.alt:''} image={image}/></Link>}
        {fileLink && <Link className={buttonClass} to={fileLink} target={'_blank'}>Download PDF</Link>}
      </div>

    </section>
    <EducationPurposeNote />
  </React.Fragment>
}

const __ShowArticleContentWithoutFile = (node) => {
  const paragraphs = node.relationships.field_content.map(getParagraph);


  return <React.Fragment>
    <section className="article container">
      <div className="article__text article-text">
          {paragraphs}
      </div>
      {node.relationships.field_related_items && <ResourceArticleSidebar nodes={node.relationships.field_related_items} /> }
    </section>

    <EducationPurposeNote />
  </React.Fragment>
}

const __ShowVideoContent = (node) => {
  const paragraphs = node.relationships.field_content.map(getParagraph);

  const video_id = node.relationships.field_attached_video?node.relationships.field_attached_video.field_video_id:false
  const video_thumbnail = video_id && node.relationships.field_attached_video.relationships.field_thumbnail?node.relationships.field_attached_video.relationships.field_thumbnail.localFile.publicURL:false
  const videoClasses = 'video wistia_embed wistia_async_' + video_id + ' popover=true popoverAnimateThumbnail=true playButton=false ' + (video_thumbnail?'stillUrl=' + video_thumbnail:'')

  const taxType = GetResourceType(node.id);
  const buttonClass = taxType === 'caregivers'?'button-green':'button-blue';

  const pBG = taxType === 'caregivers'?'#4a6f34':(taxType === 'patients'?'#222855':'#ffffff');

  return <React.Fragment>
    <section className="video-resource container">
      <div className="video-resource__media video" >
        {taxType !== '' && <span className="video__banner image-tag" css={{backgroundColor: pBG}}>{taxType}</span>}
        <div className={'video__play'} onClick={(e) => {
          e.preventDefault();
          window.Wistia.api(video_id).play();
        }}></div>
        <div className={videoClasses}>&nbsp;</div>
      </div>
      <div className="video-resource__description">
        {paragraphs}
        <a className={buttonClass} href="" onClick={(e) => {
          e.preventDefault()
          window.Wistia.api(video_id).play();
        }}>Watch Video</a>
      </div>
    </section>

    <EducationPurposeNote />
  </React.Fragment>
}

const __ShowPageContent = (node) => {
  const image = node.relationships.field_thumbnail != null?getImage(node.relationships.field_thumbnail.localFile.childImageSharp.gatsbyImageData):null
  const paragraphs = node.relationships.field_content.map(getParagraph);

  const taxType = GetResourceType(node.id);
  const buttonClass = taxType === 'caregivers'?'button-green':'button-blue';

  const linkText = node.field_attached_page_link_text?node.field_attached_page_link_text:'View';

  const linkTarget = node.relationships.field_attached_page?(node.relationships.field_attached_page.path.alias):'';


  return <>
    <section className="download container">
      {image && <a to={linkTarget} className={'download__img'} target={'_blank'}><GatsbyImage alt={node.field_thumbnail?node.field_thumbnail.alt:''} image={image}/></a>}
      <div className="download__description">
        {paragraphs}
        {!linkTarget && <span>NO PAGE ATTACHED</span>}
        {linkTarget && <Link to={linkTarget} className={buttonClass}>{linkText}</Link>}
      </div>
    </section>

    <EducationPurposeNote/>
  </>
}

const __ShowInfographicsContent = (node) => {
  const image = node.relationships.field_thumbnail != null?getImage(node.relationships.field_thumbnail.localFile.childImageSharp.gatsbyImageData):null
  const paragraphs = node.relationships.field_content.map(getParagraph);
  const fileLink = node.relationships.field_attached_file?node.relationships.field_attached_file.localFile.publicURL:false

  const taxType = GetResourceType(node.id);
  const buttonClass = taxType === 'caregivers'?'button-green':'button-blue';


  return <>
    <section className="download container">
      {image && <a to={fileLink} className={'download__img'} target={'_blank'}><GatsbyImage alt={node.field_thumbnail?node.field_thumbnail.alt:''} image={image}/></a>}
      <div className="download__description">
        {paragraphs}
        {!fileLink && <span>NO FILE ATTACHED</span>}
        {fileLink && <a className={buttonClass} href={fileLink} target={'_blank'}>Download PDF</a>}
      </div>
    </section>

    <EducationPurposeNote/>
  </>
}